import { Injectable } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'Devices',
        type: 'item',
        url: '/admin/devices',
        icon: 'feather icon-watch',
        name:'device.index'
      },
      {
        id: 'company',
        title: 'Companies',
        type: 'item',
        url: '/admin/companies',
        icon: 'feather icon-globe',
        name:'companies.index'
      },
      {
        id: 'redeem',
        title: 'Redeem Rewards',
        type: 'item',
        url: '/admin/redeems',
        icon: 'feather icon-star-on',
        name:'redeemrewards.index'
      },
      {
        id: 'settings',
        title: 'RR Settings',
        type: 'item',
        url: '/admin/app-settings',
        icon: 'feather icon-settings',
        name:'RRsetting.index'
      },
      {
        id:'UserManagement',
        title: 'User Management',
        type: 'collapse',    
        icon: 'feather icon-user',
        
        children: [
          {
            id: 'users',
            title: 'SaleR User Management',
            type: 'item',
            url: '/admin/users',
            icon: 'feather icon-user',
            name:'Salerusermanagement.index'
          },
    
          {
            id: 'admin',
            title: 'Admin User Management',
            type: 'item',
            url: '/admin/admin-users',
            icon: 'feather icon-user-check',
            name:'Adminusermanagement.index'
          }
        ]
      },
      {
        id: 'product',
        title: 'Products',
        type: 'item',
        url: '/admin/products',
        icon: 'feather icon-shopping-cart',
        name:'Product.index'
        
      },
      {
        id: 'salesVan',
        title: 'SalesVan',
        type: 'item',
        url: '/admin/salesvan',
        icon: 'feather icon-printer',
        name:'SalesVan.index'
      },
      {
        id: 'productbox',
        title: 'Product Box',
        type: 'item',
        url: '/admin/productbox',
        icon: 'feather icon-square',
        name:'ProductBox.index'
      },
      {
        id: 'stockRequest',
        title: 'Stock Request',
        type: 'item',
        url: '/admin/stock-request',
        icon: 'feather icon-speaker',
        name:'Stockrequest.index'
      },

      {
        id: 'category',
        title: 'Product Category',
        type: 'item',
        url: '/admin/product-category',
        icon: 'feather icon-star',
        name:'ProductCategory.index'
      },
      {
        id: 'purchaseList',
        title: 'Purchase List',
        type: 'item',
        url: '/admin/purchase-list',
        icon: 'feather icon-star',
        name:'PurchaseList.index'
      },
      {
        id: 'report',
        title: 'Reports',
        type: 'item',
        url: '/admin/report',
        icon: 'feather icon-star',
        name:'Report.index'
      },

      
      {
        id: 'stock-balance',
        title: 'Stock Balances',
        type: 'item',
        url: '/admin/stock-balance',
        icon: 'feather icon-layout',
        name:'stockBalance.index'
      },

      {
        id: 'shop-balance',
        title: 'Shop stock Balances',
        type: 'item',
        url: '/admin/shop-balance',
        icon: 'feather icon-layout',
        name:'ShopStockBalance.index'
      },
      {
        id:'log-history',
        title: 'Log History',
        type: 'item',
        url: '/admin/log-history',
        icon: 'feather icon-book',
        name:'LogHistory.index'

      },
      {
        id:'admin-user-role-management',
        title: 'Admin User Role Management',
        type: 'item',
        url: '/admin/user-role-management',
        icon: 'feather icon-users',
        name:'AdminUserRoleManagement.index'

      }

    ]

  }];
  const NavigationItemsBP = [
    {
      id: 'navigation',
      title: 'Navigation',
      type: 'group',
      icon: 'feather icon-monitor',
      children: [
       
        {
          id: 'company',
          title: 'Companies',
          type: 'item',
          url: '/admin/companies',
          icon: 'feather icon-globe',
          name:'companies.index'
        },
        {
          id:'UserManagement',
          title: 'User Management',
          type: 'collapse',    
          icon: 'feather icon-user',
          
          children: [
            {
              id: 'users',
              title: 'SaleR User ',
              type: 'item',
              url: '/admin/users',
              icon: 'feather icon-user',
              name:'Salerusermanagement.index'
            },
      
            {
              id: 'admin',
              title: 'Admin User ',
              type: 'item',
              url: '/admin/admin-users',
              icon: 'feather icon-user-check',
              name:'Adminusermanagement.index'
            },
            {
              id: 'shopuser',
              title: 'Shop User ',
              type: 'item',
              url: '/admin/shop-users',
              icon: 'feather icon-user-check',
              name:'shopusermanagement.index'
            },
            {
              id: 'warrentyuser',
              title: 'Warrenty User ',
              type: 'item',
              url: '/admin/warrenty-users',
              icon: 'feather icon-user-check',
              name:'warrentyusermanagement.index'
            },
            {
              id: 'repairuser',
              title: 'Repair User ',
              type: 'item',
              url: '/admin/repair-users',
              icon: 'feather icon-user-check',
              name:'repairusermanagement.index'
            }
          ]
        },
       
       
        {
          id: 'product',
          title: 'Products',
          type: 'item',
          url: '/admin/products',
          icon: 'feather icon-shopping-cart',
          name:'Product.index'
          
        },

       
        {
          id: 'salesVan',
          title: 'SalesVan',
          type: 'item',
          url: '/admin/salesvan',
          icon: 'feather icon-printer',
          name:'SalesVan.index'
        },
        {
          id: 'productbox',
          title: 'Product Box',
          type: 'item',
          url: '/admin/productbox',
          icon: 'feather icon-square',
          name:'ProductBox.index'
        },
        {
          id: 'stockRequest',
          title: 'Stock Request',
          type: 'item',
          url: '/admin/stock-request',
          icon: 'feather icon-speaker',
          name:'Stockrequest.index'
        },
  
        {
          id: 'category',
          title: 'Product Category',
          type: 'item',
          url: '/admin/product-category',
          icon: 'feather icon-star',
          name:'ProductCategory.index'
        },
        {
          id: 'report',
          title: 'Reports',
          type: 'item',
          url: '/admin/report',
          icon: 'feather icon-star',
          name:'Report.index'
        },
  
        
        {
          id: 'stock-balance',
          title: 'Stock Balances',
          type: 'item',
          url: '/admin/stock-balance',
          icon: 'feather icon-layout',
          name:'stockBalance.index'
        },
  
        {
          id: 'shop-balance',
          title: 'Shop stock Balances',
          type: 'item',
          url: '/admin/shop-balance',
          icon: 'feather icon-layout',
          name:'ShopStockBalance.index'
        },

        {
          id:'admin-user-role-management',
          title: 'Admin User Role Management',
          type: 'item',
          url: '/admin/user-role-management',
          icon: 'feather icon-users',
          name:'AdminUserRoleManagement.index'
  
        },
        {
          id: 'impactProof',
          title: 'Impact Proof',
          type: 'item',
          url: '/admin/impact-proof',
          icon: 'feather icon-star',
          name:'impactProof.index'
        },
  
      ]
  
    },
     {
      id:'Shop',
      title: 'Shop',
      type: 'collapse',    
      icon: 'feather icon-shopping-cart',
      
      children: [
        {
          id:'bp-product-category',
          title: 'Category',
          type: 'item',
          url: '/admin/bp-shop/category',
          icon: 'feather icon-star',
          name:'PbProductCategory.index'
  
        },
        {
          id:'bp-product-brand',
          title: 'Brand',
          type: 'item',
          url: '/admin/bp-shop/brand',
          icon: 'feather icon-star',
          name:'PbProductBrand.index'
  
        },
        {
          id:'bp-product-color',
          title: 'Color',
          type: 'item',
          url: '/admin/bp-shop/color',
          icon: 'feather icon-star',
          name:'PbProductColor.index'
  
        },
        {
          id:'bp-product',
          title: 'Product',
          type: 'item',
          url: '/admin/bp-shop/product',
          icon: 'feather icon-star',
          name:'PbProducts.index'
  
        },
        {
          id:'bp-product-bulk',
          title: 'Product Bulk create',
          type: 'item',
          url: '/admin/bp-shop/product/bulk-create',
          icon: 'feather icon-star',
          name:'PbProductBulk.index'
  
        }
      ]
    } ,
    {
      id:'Insurance',
      title: 'Insurance',
      type: 'collapse',    
      icon: 'feather icon-file',
      
      children: [
        {
          id: 'insurance-claim',
          title: 'Insurance Claims',
          type: 'item',
          url: '/admin/bp-insurance/claims',
          icon: 'mdi mdi-file',
          name:'insuranceClaims.index'
        },

        {
          id:'insurance-registration',
          title: 'Insurance Registration',
          type: 'item',
          url: '/admin/bp-insurance/registration',
          icon: 'mdi mdi-view-carousel',
          name:'insuranceRegistration.create'
  
        }
      ]
    }  

   
  ];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }

  public getBP() {
    return NavigationItemsBP;
  }
}
