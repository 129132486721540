import { Component } from '@angular/core';

@Component({
    selector: 'app-spinner-overlay',
    template: `
    <mat-spinner diameter="80" color="accent"></mat-spinner>`,
    styleUrls: ['./spinner-overlay.component.scss'],
})
export class SpinnerOverlayComponent {
    constructor() { }
}