import { Injectable } from '@angular/core';
import { BehaviorSubject,Observable } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class DataService {

    private company = new BehaviorSubject<boolean>(false);
      setCompany(data) {
        console.log('set company called',data)
        this.company.next(data);
      }
      getCompany(): Observable<any> {
        return this.company.asObservable();
      }

      array2jstree(allPermissions,selectedPermisson=''){

       
       console.log('allPermissions',allPermissions);
        selectedPermisson =selectedPermisson!=''?JSON.parse(selectedPermisson):[];
         
        let treeHtml='';
       
        allPermissions =JSON.parse(allPermissions);
       
        allPermissions.forEach((item)=>{
             
              if(Array.isArray(item[Object.keys(item)[0]])){

                treeHtml += "<li>"+Object.keys(item)[0];
                treeHtml += "<ul>"
                item[Object.keys(item)[0]].forEach((sub)=>{
                  let checked='';
                  if(selectedPermisson.includes(Object.keys(sub)[0])){
                    checked ='class="jstree-clicked"';
                  }
                 treeHtml += "<li id='"+Object.keys(sub)[0]+"'  "+checked+" ><a href=\"#\" "+checked+" >"+ sub[Object.keys(sub)[0]]+"</a></li>";

                });
                treeHtml += "</ul>"
                treeHtml += '</li>';
              
              }else{
               
                
              }
        });
        
        return "<ul>"+treeHtml+"</ul>";

      }


      isHavePermission(urlname){
        // let superAdminUsers =['books@rightviewgroup.com','info@rightviewgroup.com'] 73bb94
        let superAdminUsers =[4,8]
        let userPermission = JSON.parse(localStorage.getItem('up'));
        let info = window.sessionStorage.getItem('userInfo');
        let userInfo;
        if (info) {
          userInfo= JSON.parse(info);
        }
        if(superAdminUsers.includes(userInfo.Id) ){
          return true;
        }
       
          if(userPermission.includes(urlname)){
              return true;
          }else{
              return false
          }
        }
      

}