import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import {NextConfig} from '../../../../app-config';


@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  public nextConfig: any;
  public menuClass: boolean;
  public collapseStyle: string;
  public windowWidth: number;
  public userInfo:any;
  enable: boolean;
  
  @Output() onNavCollapse = new EventEmitter();
  @Output() onNavHeaderMobCollapse = new EventEmitter();

  constructor( private dataService:DataService) {
    this.nextConfig = NextConfig.config;
    this.menuClass = false;
    this.collapseStyle = 'none';
    this.windowWidth = window.innerWidth;

    let info = window.sessionStorage.getItem('userInfo');
    
    if (info) {
      this.userInfo= JSON.parse(info);
      if(this.userInfo.FirmId==1 && this.userInfo.Id!=4){
        this.dataService.setCompany(true);
      }else{
        this.dataService.setCompany(false);
      }
    }
   
    
  }

  ngOnInit() {
    this.dataService.getCompany().subscribe((res)=>{
      this.enable=res;
    });
   }

  toggleMobOption() {
    this.menuClass = !this.menuClass;
    this.collapseStyle = (this.menuClass) ? 'block' : 'none';
  }
  onChange(event){
    this.dataService.setCompany(event);
  }
  navCollapse() {
    if (this.windowWidth >= 992) {
      this.onNavCollapse.emit();
    } else {
      this.onNavHeaderMobCollapse.emit();
    }
  }

}
