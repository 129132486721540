import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    // private baseUrl = 'http://breakprotrctionapi.azurewebsites.net/api';
    // private baseUrl = 'http://trendviser.com/api';
     private baseUrl = 'http://breakprotrctionapi.azurewebsites.net/api/';
    private imageUrl = 'http://breakprotrctionapi.azurewebsites.net/';

    constructor(private http: HttpClient) { }

    login(request): Observable<any> {
        return this.http.post(`${this.baseUrl}/AdminUser/Login`, request);
    }

    get userInfo() {
        let info = window.sessionStorage.getItem('userInfo');
        if (info) {
            return JSON.parse(info);
        }
        else { return null }
    }
    set userInfo(value: any) {
        if (value) {
            window.sessionStorage.setItem('userInfo', JSON.stringify(value))
        }
    }
}