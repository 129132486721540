import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Subscription } from "rxjs";
import { Observable } from "rxjs/Observable";
import { finalize, map } from "rxjs/operators";
import { SpinnerOverlayService } from "./spinner.service";

export class AppHttpInterceptor implements HttpInterceptor {
    constructor(private readonly spinnerOverlayService: SpinnerOverlayService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): any {
        return next.handle(req).pipe(
            // We use the map operator to change the data from the response
            map(resp => {
                // Several HTTP events go through that Observable 
                // so we make sure that this is a HTTP response
                if (resp instanceof HttpResponse) {
                    // Just like for request, we create a clone of the response
                    // and make changes to it, then return that clone     
                    //return resp.clone({ body: [{ title: 'Replaced data in interceptor' }] });
                    console.log(resp);
                    return resp;
                }
            },
            ));
    }
}